import React, { useState } from 'react';
import { useFlexSearch } from 'react-use-flexsearch';
import { graphql, Link } from 'gatsby';

const SearchBar = ({
  data: {
    localSearchPages: { index, store },
  },
}) => {
  const [query, setQuery] = useState('');
  const results = useFlexSearch(query, index, store);
  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setQuery(e.target?.query?.value || '');
        }}
      >
        <input name="query" />
        <button type="submit">search</button>
        <button onClick={() => setQuery('')}>clear</button>
      </form>
      <h1>Results</h1>
      <ul>
        {results.map((result) => (
          <li key={result.id}>
            <Link to={result.path}>{result.excerpt}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
export const query = graphql`
  query getSearchData {
    localSearchPages {
      index
      store
    }
  }
`;

export default SearchBar;
